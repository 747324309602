let seq = 0
function nextId() {
    return `id_${++seq}_`
}

export type Project = {
    id: string
    name: string
    desc: string
}

export type DocLinkData = {
    title: string
    desc: string
    href: string
}

export type Server = {
    id: string
    name: string
    desc: string
}

export type PublicLink = {
    id: string
    name: string
    url: string
    icon: string
}

/**
 * 서버 목록
 */
export const SERVERS: Server[] = [
    { id: 'boa', name: '개발 서버', desc: '192.168.114.71' },
    { id: 'joy', name: '젠킨스 서버', desc: '192.168.114.72' },
    { id: 'toy', name: '소스 서버', desc: '192.168.114.73' },
]

/**
 * 링크 목록
 */
export const PUBLIC_LINKS: PublicLink[] = [
    {
        id: nextId(),
        name: '크레디트라인 메타',
        url: 'https://meta.labcl.net',
        icon: '/images/icons/data-science.png',
    },
    {
        id: nextId(),
        name: 'FDS AS-IS',
        url: 'https://fds-asis.labcl.net',
        icon: '/images/icons/data-science.png',
    },
    {
        id: nextId(),
        name: 'FDS 웹',
        url: 'https://fds.labcl.net',
        icon: '/images/icons/data-science.png',
    },
    {
        id: nextId(),
        name: 'ZERO 웹',
        url: 'https://zero.labcl.net',
        icon: '/images/icons/symbol01.png',
    },
    {
        id: nextId(),
        name: 'CLOVER 웹',
        url: 'https://clover.labcl.net',
        icon: '/images/icons/symbol01.png',
    },
    {
        id: nextId(),
        name: 'CROWD 웹',
        url: 'https://crowd.labcl.net',
        icon: '/images/icons/symbol01.png',
    },
    {
        id: nextId(),
        name: '룰 웹',
        url: 'https://cloverrule.labcl.net',
        icon: '/images/icons/symbol01.png',
    },
    {
        id: nextId(),
        name: 'FDS 문서(지금 이 사이트)',
        url: 'https://doc.labcl.net',
        icon: '/images/icons/documents.png',
    },
    {
        id: nextId(),
        name: '소스서버',
        url: 'https://git.labcl.net',
        icon: '/images/icons/git.png',
    },
    {
        id: nextId(),
        name: '젠킨스',
        url: 'https://jenkins.labcl.net',
        icon: '/images/icons/jenkins.png',
    },
]

/**
 * 프로젝트 목록
 */
export const PROJECTS: Project[] = [
    {
        id: 'meta/server.git',
        name: '크레디트라인 메타 서버',
        desc: 'Springboot',
    },
    {
        id: 'meta/meta-web.git',
        name: '크레디트라인 메타 웹',
        desc: 'NextJS, ReactJS',
    },
    {
        id: 'fds/server.git',
        name: 'FDS API 서버',
        desc: 'Springboot',
    },
    {
        id: 'fds/web.git',
        name: 'FDS 웹',
        desc: 'NextJS, ReactJS',
    },
    {
        id: 'fds/doc.git',
        name: 'FDS 문서',
        desc: 'NextJS, ReactJS',
    },

    // {
    //     id: 'fds/meta-cli.git',
    //     name: '메타 생성 명령',
    //     desc: 'NodeJS',
    // },
]

/**
 * 백엔드 문서 링크들
 */
export const BACKEND_DOC_LINKS: DocLinkData[] = [
    {
        title: '서버 개발 환경 설정',
        desc: 'FDS API 서버의 개발환경 설정 방법을 설명합니다.',
        href: '/doc/how-to-setup-server-development',
    },
    {
        title: 'FDS API 명세',
        desc: 'FDS API 설명과 파라미터, 응답 데이터 등을 확인할 수 있습니다.',
        href: 'https://fds.labcl.net/fds-server/p/api-doc',
    },
    {
        title: 'MyBatis 개발 가이드',
        desc: 'MyBatis 개발 가이드 및 주의 사항을 설명합니다.',
        href: '/doc/how-to-use-mybatis',
    },
    {
        title: 'Auditing 개발 가이드',
        desc: '시스템의 Auditing과 관련한 개발 가이드입니다.',
        href: '/doc/how-to-audit',
    },
]

/**
 * 프론트엔트 문서 링크들
 */
export const FRONTEND_DOC_LINKS: DocLinkData[] = [
    {
        title: '웹 개발 환경 설정',
        desc: 'FDS WEB 개발환경 설정 방법을 설명합니다.',
        href: '/doc/how-to-setup-web-development',
    },
    {
        title: 'pnpm 사용 가이드',
        desc: 'FDS 프로젝트에서 사용중인 패키지 매니저 pnpm을 설명합니다.',
        href: '/doc/how-to-use-pnpm',
    },
]

// /**
//  * 메타 문서 링크들
//  */
// export const META_DOC_LINKS: DocLinkData[] = [
//     {
//         title: '메타 명령행 환경 설정',
//         desc: '메타 명령행 소스의 개발환경 설정 방법을 설명합니다.',
//         href: '/doc/how-to-use-meta-cli',
//     },
//     {
//         title: '메타 명령행 이용 가이드',
//         desc: '메타 명령행 프로그램의 사용법을 설명합니다.',
//         href: '/doc/how-to-use-pnpm',
//     },
// ]

/**
 * 기타 문서 링크들
 */
export const ETC_DOC_LINKS: DocLinkData[] = [
    {
        title: '개발자 PC의 설정',
        desc: '개발자 PC를 설정하는 방법을 설명합니다.',
        href: '/doc/how-to-setup-developer-pc',
    },
    {
        title: '시스템 운영 가이드',
        desc: '서버, 도커 등의 시스템 운영 가이드를 설명합니다.',
        href: 'not-ready',
    },
    {
        title: 'Java 컨벤션',
        desc: '자바 소스 코드 컨벤션을 설명합니다.',
        href: '/doc/java-convention',
    },
    {
        title: 'SQL 컨벤션',
        desc: 'SQL 쿼리 작성 컨벤션을 설명합니다.',
        href: 'not-ready',
    },
]

import { flatSx } from '@/lib/sx-props'
import { routerPush } from '@/lib/urls'
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined'
import LaunchIcon from '@mui/icons-material/Launch'
import SentimentDissatisfiedOutlinedIcon from '@mui/icons-material/SentimentDissatisfiedOutlined'
import { Box, ButtonBase, SxProps, Typography } from '@mui/material'
import clsx from 'clsx'
import { toast } from 'react-toastify'
import { rootSx } from './style'

const isExternal = (href: string) => {
    return href.startsWith('http://') || href.startsWith('https://')
}

type Props = {
    sx?: SxProps
    className?: string
    title: string
    desc: string
    href: string
}

export default function DocLink(props: Props) {
    const { sx, className, title, desc, href } = props

    const handleClickDocLink = (href: string) => {
        if (href === 'not-ready') {
            toast.warn('준비중입니다')
            return
        }

        if (href.startsWith('https://') || href.startsWith('http://')) {
            window.open(href, '_blank')
        } else {
            routerPush(href)
        }
    }

    return (
        <ButtonBase
            className={clsx('DocLink-root', className, {
                'DocLink-notReady': href === 'not-ready',
            })}
            component="div"
            sx={flatSx(rootSx, sx)}
            onClick={() => handleClickDocLink(href)}
        >
            <Typography className="DocLink-title" component="div" variant="subtitle1">
                {title}
            </Typography>
            <Typography className="DocLink-desc" component="div" variant="body1">
                {desc}
            </Typography>

            <Box className="DocLink-icon">
                {href === 'not-ready' && <SentimentDissatisfiedOutlinedIcon />}
                {href !== 'not-ready' && (isExternal(href) ? <LaunchIcon /> : <ArticleOutlinedIcon />)}
            </Box>
        </ButtonBase>
    )
}

import { SxProps } from '@mui/material'

export const rootSx: SxProps = {
    position: 'relative',
    display: 'block',
    border: '1px solid #ddd',
    borderRadius: 1,
    background: '#f5f5f5',
    pl: 8,
    pr: 2,
    py: 1.5,

    '&.DocLink-notReady': {
        '& .DocLink-icon': {
            color: '#ccc',
        },
        '& .DocLink-title': {
            color: '#aaa',
        },
        '& .DocLink-desc': {
            color: '#aaa',
        },
    },

    '& .DocLink-title': {
        fontWeight: 700,
        color: 'primary.main',
    },

    '& .DocLink-desc': {
        fontWeight: 500,
        mt: 1,
    },

    '& .DocLink-icon': {
        position: 'absolute',
        top: '50%',
        left: 16,
        transform: 'translateY(-50%)',
        color: '#666',
    },
}

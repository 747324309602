import MainLayout from '@/layouts/MainLayout'
import Dashboard from '@/features/Dashboard'
import LoginHome from '@/features/LoginHome/LoginHome'
import RedirectOnLogout from '@/components/RedirectOnLogout'

export default function Web() {
    return (
        <RedirectOnLogout>
            <MainLayout title="대시보드">
                <Dashboard />
            </MainLayout>
        </RedirectOnLogout>
    )
}

import { SxProps } from '@mui/material'

export const rootSx: SxProps = {
    p: 3,

    '& .MuiTableContainer-root': {
        '& td,& th': {
            whiteSpace: 'nowrap',
        },
    },

    '& .Dashboard-linkContainer': {
        width: '100%',
        p: 0,
        mx: 0,
        '& >  li': {
            listStyle: 'none',
            width: '100%',
            '& + li': {
                mt: 2,
            },
        },
    },
}

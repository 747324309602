import CustomTableBody from '@/components/CustomTableBody'
import Link from '@/components/Link'
import {
    Alert,
    AlertTitle,
    Box,
    ButtonBase,
    Stack,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2'
import Image from 'next/image'
import DocLink from './components/DocLink'
import { BACKEND_DOC_LINKS, ETC_DOC_LINKS, FRONTEND_DOC_LINKS, PROJECTS, PUBLIC_LINKS, SERVERS } from './documents-data'
import { rootSx } from './style'
import { useLoginProfile } from '@/auth/useLoginProfile'
import { Portlet, PortletContent, PortletHeader } from '@local/ui-common'

export default function Dashboard() {
    const login = useLoginProfile()
    return (
        <Box className="Dashboard-root" sx={rootSx}>
            <Grid2 container spacing={1}>
                {login?.profile.userId === 1 && (
                    <Grid2 xs={12} md={6} lg={4}>
                        <Portlet>
                            <PortletHeader>
                                <Typography variant="h5">서버</Typography>
                            </PortletHeader>
                            <PortletContent noPadding>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>#</TableCell>
                                                <TableCell>ID</TableCell>
                                                <TableCell>이름</TableCell>
                                                <TableCell>비고</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <CustomTableBody stripe className="Dashboard-serversTable">
                                            {SERVERS.map((it, idx) => (
                                                <TableRow key={it.id}>
                                                    <TableCell>{idx + 1}</TableCell>
                                                    <TableCell>{it.id}</TableCell>
                                                    <TableCell>{it.name}</TableCell>
                                                    <TableCell>{it.desc}</TableCell>
                                                </TableRow>
                                            ))}
                                        </CustomTableBody>
                                    </Table>
                                </TableContainer>
                            </PortletContent>
                        </Portlet>
                    </Grid2>
                )}

                <Grid2 xs={12} md={6} lg={4}>
                    <Portlet>
                        <PortletHeader>
                            <Typography variant="h5">공개 링크</Typography>
                        </PortletHeader>
                        <PortletContent noPadding>
                            <Alert>
                                <AlertTitle>공개된 링크</AlertTitle>
                                향후 일부 링크는 비공개 예정
                            </Alert>
                            <TableContainer sx={{ maxHeight: 410 }}>
                                <Table>
                                    <CustomTableBody className="Dashboard-serversTable">
                                        {PUBLIC_LINKS.map((it, idx) => (
                                            <TableRow key={it.id}>
                                                <TableCell sx={{ p: 0 }}>
                                                    <ButtonBase
                                                        component={Link}
                                                        href={it.url}
                                                        target="_blank"
                                                        sx={{
                                                            borderRadius: 0,
                                                            px: 2,
                                                            py: 1.5,
                                                            display: 'block',
                                                        }}
                                                    >
                                                        <Stack direction="row" spacing={2} alignItems="center">
                                                            <Image src={it.icon} alt="" width={40} height={40} />

                                                            <Box>
                                                                <Typography component="div" color="primary.main">
                                                                    {it.name}
                                                                </Typography>
                                                                <Typography component="div" color="text.primary">
                                                                    {it.url}
                                                                </Typography>
                                                            </Box>
                                                        </Stack>
                                                    </ButtonBase>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </CustomTableBody>
                                </Table>
                            </TableContainer>
                        </PortletContent>
                    </Portlet>
                </Grid2>

                <Grid2 xs={12} md={6} lg={4}>
                    <Portlet>
                        <PortletHeader>
                            <Typography variant="h5">프로젝트</Typography>
                        </PortletHeader>
                        <PortletContent noPadding>
                            <Alert>
                                <AlertTitle>소스코드 다운로드 예시</AlertTitle>
                                git clone https://git.labcl.net/fds/server.git
                            </Alert>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>#</TableCell>
                                            <TableCell>이름</TableCell>
                                            <TableCell>비고</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <CustomTableBody stripe className="Dashboard-projectsTable">
                                        {PROJECTS.map((it, idx) => (
                                            <TableRow key={it.id}>
                                                <TableCell>{idx + 1}</TableCell>
                                                <TableCell>
                                                    {it.name}
                                                    <Typography sx={{ color: 'primary.dark', mt: 0.5 }}>
                                                        {it.id}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell>{it.desc}</TableCell>
                                            </TableRow>
                                        ))}
                                    </CustomTableBody>
                                </Table>
                            </TableContainer>
                        </PortletContent>
                    </Portlet>
                </Grid2>
                <Grid2 xs={12} md={6} lg={4}>
                    <Portlet>
                        <PortletHeader>
                            <Typography variant="h5">백엔드 문서</Typography>
                        </PortletHeader>
                        <PortletContent>
                            <Box className="Dashboard-linkContainer" component="ul">
                                {BACKEND_DOC_LINKS.map((it) => (
                                    <li key={it.href}>
                                        <DocLink title={it.title} desc={it.desc} href={it.href} />
                                    </li>
                                ))}
                            </Box>
                        </PortletContent>
                    </Portlet>
                </Grid2>
                <Grid2 xs={12} md={6} lg={4}>
                    <Portlet>
                        <PortletHeader>
                            <Typography variant="h5">프론트엔드 문서</Typography>
                        </PortletHeader>
                        <PortletContent>
                            <Box className="Dashboard-linkContainer" component="ul">
                                {FRONTEND_DOC_LINKS.map((it) => (
                                    <li key={it.href}>
                                        <DocLink title={it.title} desc={it.desc} href={it.href} />
                                    </li>
                                ))}
                            </Box>
                        </PortletContent>
                    </Portlet>
                </Grid2>
                {/* <Grid2 xs={12} md={6} lg={4}>
                    <Portlet>
                        <PortletHeader>
                            <Typography variant="h5">메타 시스템 문서</Typography>
                        </PortletHeader>
                        <PortletContent>
                            <Box className="Dashboard-linkContainer" component="ul">
                                {META_DOC_LINKS.map((it) => (
                                    <li key={it.href}>
                                        <DocLink title={it.title} desc={it.desc} href={it.href} />
                                    </li>
                                ))}
                            </Box>
                        </PortletContent>
                    </Portlet>
                </Grid2> */}
                <Grid2 xs={12} md={6} lg={4}>
                    <Portlet>
                        <PortletHeader>
                            <Typography variant="h5">기타 문서</Typography>
                        </PortletHeader>
                        <PortletContent>
                            <Box className="Dashboard-linkContainer" component="ul">
                                {ETC_DOC_LINKS.map((it) => (
                                    <li key={it.href}>
                                        <DocLink title={it.title} desc={it.desc} href={it.href} />
                                    </li>
                                ))}
                            </Box>
                        </PortletContent>
                    </Portlet>
                </Grid2>
            </Grid2>
        </Box>
    )
}
